<!--
 * @Author: Musa Tabitay
 * @Date: 2022-01-01 23:28:13
 * @LastEditTime: 2022-01-10 16:00:44
-->

<template>
  <div class="layout-container">
    <!-- 子路由 -->
    <router-view />
    <!-- 标签导航栏 Tabbar -->
    <van-tabbar
      class="layout-tabbar"
      v-model="active"
      active-color="#ee0a24"
      inactive-color="#000"
    >
      <van-tabbar-item to="/" name="home">
        <i slot="icon" class="toutiao toutiao-shouye"></i>
        <span class="text">باس بەت</span>
      </van-tabbar-item>
      <van-tabbar-item to="/my" name="my">
        <i slot="icon" class="toutiao toutiao-wode"></i>
        <span class="text">جەكەلىك</span>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'LayoutIndex',
  data () {
    return {
      active: ''
    }
  },
  mounted () {
    this.active = this.$route.name
  }
}
</script>

<style lang="less" scoped>
.layout-container {
  .layout-tabbar {
    direction: rtl;
    i.toutiao {
      font-size: 40px;
    }
  }
}
</style>
